const headers = [
  {
    text: "Nombre Charla",
    value: "conferenceName",
    sortable: false,
  },
  {
    text: "Conferencista",
    value: "speaker",
    sortable: false,
  },
  {
    text: "Fecha",
    value: "conference_schedule_time.conference_schedule.availableDate",
    sortable: false,
  },
  {
    text: "Hora",
    value: "conference_schedule_time.description",
    sortable: false,
  },
  {
    text: "Nombre empresa",
    value: "user.company.name",
    sortable: false,
  },
  {
    text: "",
    value: "actions",
    sortable: false,
    width: 50,
  },
];
export default headers;

<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="hour"
          dense
          :disabled="disabled"
          :outlined="outlined"
          :hide-details="hideDetails"
          :error-messages="errors"
          :label="label"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="menu"
        v-model="hour"
        full-width
        format="ampm"
        @input="closePicker"
      ></v-time-picker>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "TimePicker",
  props: {
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Array, Object, String],
      default: null,
    },
  },
  data() {
    return {
      hour: this.value,
      menu: false,
    };
  },
  methods: {
    closePicker: function () {
      this.menu = false;
      this.$emit("input", this.hour);
      this.$refs.menu.save(this.hour);
    },
  },
};
</script>

<template>
  <div class="pa-3">
    <div class="d-flex justify-center">
      <div class="col-sm-12 col-md-5 col-lg-3 d-flex flex-column">
        <h2 v-text="internalName"></h2>
        <p class="my-0">Hoy es <strong v-text="dateCurrentFormat"></strong></p>
      </div>
    </div>
    <div class="row justify-center mt-2 mx-0">
      <div class="col-12 my-1 py-1">
        <v-text-field
          v-model="params.filter"
          label="Nombre empresa"
          v-on:keyup.enter="search"
          outlined
          dense
          single-line
          hide-details
        />
      </div>
      <div class="col-12 my-1 py-1">
        <v-select
          v-model="params.company"
          :items="companies"
          item-text="name"
          item-value="id"
          outlined
          dense
          label="Empresa"
          hide-details
        ></v-select>
      </div>
      <div class="col-12 my-1 py-1">
        <v-select
          v-model="params.keywords"
          :items="keywords"
          :multiple="true"
          item-text="name"
          item-value="id"
          outlined
          dense
          label="Palabras clave"
          hide-details
        ></v-select>
      </div>
      <div class="col-12 my-1 py-1">
        <v-select
          v-model="params.conferenceRoom"
          :items="conferenceRooms"
          item-text="name"
          item-value="id"
          outlined
          dense
          label="Salon"
          hide-details
        ></v-select>
      </div>
      <div class="col-12 my-1 py-1">
        <v-text-field
          label="Nombre de Charla"
          v-model="params.conferenceName"
          outlined
          dense
          single-line
          hide-details
        ></v-text-field>
      </div>
      <div class="col-12 my-1 py-1">
        <date-picker
          label="Fecha"
          v-model="date"
          :is-range="true"
          :allowed-dates="availableDates"
          :key="dateHoursKey"
        ></date-picker>
      </div>
      <div class="col-12 my-1 py-1">
        <time-picker
          label="Hora inicio"
          v-model="startHour"
          :key="dateHoursKey"
        ></time-picker>
      </div>
      <div class="col-12 my-1 py-1">
        <time-picker
          label="Hora fin"
          v-model="endHour"
          :key="dateHoursKey"
        ></time-picker>
      </div>

      <div class="col-12 col-md-6 my-2 py-2 d-flex justify-end">
        <v-btn
          color="primary"
          @click="search"
          class="white--text text-none elevation-1"
        >
          <v-icon class="mr-1">mdi-magnify</v-icon>
          Buscar
        </v-btn>
        <v-btn
          color="default"
          @click="clean"
          class="text-none elevation-1 ml-2"
        >
          <v-icon class="mr-1">mdi-broom</v-icon>
          Limpiar
        </v-btn>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col-12 my-1 py-1 px-md-12">
        <div class="text-center" v-if="params">
          <data-table
            ref="conferenceBookings"
            :params="params"
            :url="url"
            :headers="headers"
          >
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    @click="show(item)"
                    class="ma-2 elevation-1"
                    v-bind="attrs"
                    v-on="on"
                    small
                    color="primary"
                  >
                    <v-icon class="mr-1">mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Ver detalles de la charla</span>
              </v-tooltip>
            </template>
          </data-table>
        </div>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col-12 px-md-12 col-md-3 text-center">
        <h4 class="secondary py-2 rounded">Programación</h4>
      </div>
    </div>
    <div class="row mx-0">
      <div
        class="custom-v-tab-item flex justify-center"
        v-if="Object.keys(conferenceScheduleTimes).length"
      >
        <div class="col-sm-12 col-md-5 col-lg-3">
          <v-tabs
            background-color="bg-transparent"
            v-model="tab"
            align-with-title
            height="80"
            class="mb-3"
            show-arrows
          >
            <v-tab
              v-for="(dates, key) in conferenceScheduleTimes"
              :key="key"
              class="rounded-lg elevation-3"
            >
              <div class="d-flex flex-column" id="item-tab">
                <span v-text="getDayWeek(key)" class="text-center"></span>
                <span
                  v-text="getDayMonth(key)"
                  class="text-center font-weight-bold"
                ></span>
                <span v-text="getMonth(key)"></span>
              </div>
            </v-tab>
          </v-tabs>
          <div class="row">
            <div class="col-12">
              <v-tabs-items v-model="tab">
                <v-tab-item
                  v-for="(dates, key) in conferenceScheduleTimes"
                  :key="key"
                >
                  <v-expansion-panels tile flat accordion class="my-4">
                    <v-expansion-panel
                      v-for="(rooms, indexRoom) in getRooms()"
                      :key="rooms.id"
                      class="mt-2 white--text rounded-lg"
                    >
                      <v-expansion-panel-header
                        class="white--text elevation-6 rounded-lg"
                        color="primary"
                      >
                        <span v-text="indexRoom.split('-')[0]"></span>
                        <div class="mr-auto"></div>
                        <span class="text-right mx-1">VER</span>
                        <template v-slot:actions>
                          <v-icon color="white"> mdi-chevron-down </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content
                        tile
                        flat
                        accordion
                        class="pa-1"
                      >
                        <v-card
                          class="py-2 px-3 mb-3 black--text card-border rounded-lg"
                          v-for="(room, index) in rooms"
                          :key="index"
                          elevation="3"
                          @click="show(room)"
                        >
                          <div class="row">
                            <div class="col-10">
                              <div class="d-flex flex-column">
                                <span
                                  v-text="room.companyName"
                                  class="w-100 font-weight-bold"
                                >
                                </span>
                                <span
                                  class="w-100"
                                  v-text="room.conferenceName"
                                ></span>
                              </div>
                            </div>
                            <div class="col-2 custom-icon">
                              <template>
                                <v-icon> mdi-chevron-right </v-icon>
                              </template>
                            </div>
                          </div>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </div>
        </div>
      </div>
    </div>
    <conference-detail-modal ref="conferenceDetailModal" />
  </div>
</template>
<script>
import sessionMixin from "@/mixins/sessionMixin";
import moment from "moment";
import loaderMixin from "@/mixins/loaderMixin";
import DatePicker from "@/components/date-picker/DatePicker";
import TimePicker from "@/components/date-picker/TimePicker";
import ConferenceDetailModal from "./components/ConferenceDetailModal";
import DataTable from "@/components/data-table/DataTable";
import headers from "./data/Headers";

export default {
  name: "Conferences",
  mixins: [sessionMixin, loaderMixin],
  async created() {
    moment.locale("es");
    this.params.fairId = this.fair.id;
    await this.getConferenceRooms();
    await this.getKeywords();
    await this.getCompanies();
    await this.getConferenceScheduleTimes();
    await this.getAvailableDates();
  },
  components: { DatePicker, TimePicker, ConferenceDetailModal, DataTable },
  computed: {
    fair() {
      return this.$store.getters["fairs/fair"];
    },
    dateCurrentFormat() {
      return moment().format("dddd, D ## MMMM").replace("##", "de");
    },
    internalName() {
      return (
        this.fair?.app_modules?.filter((item) => item.modules == "TECH")[0]
          ?.internalName ?? "Programación Charlas Técnicas y Ágoras"
      );
    },
  },
  data() {
    return {
      availableDates: [],
      companies: [],
      conferenceRooms: [],
      conferenceScheduleTimes: [],
      date: null,
      dateHoursKey: false,
      endHour: null,
      headers,
      keywords: [],
      params: {
        date: null,
        endHour: null,
        fairId: null,
        keywords: null,
        company: null,
        conferenceRoom: null,
        conferenceName: null,
        startDate: null,
        startHour: null,
        pageSize: 10,
      },
      startHour: null,
      url: process.env.VUE_APP_API_AUTH_URL + "conference-bookings/successes",
      tab: null,
    };
  },
  methods: {
    clean() {
      this.params.date = null;
      this.params.keywords = null;
      this.params.company = null;
      this.params.conferenceRoom = null;
      this.params.conferenceName = null;
      this.params.startDate = null;
      this.params.date = null;
      this.params.startHour = null;
      this.params.endHour = null;
      this.date = null;
      this.startHour = null;
      this.endHour = null;
      this.dateHoursKey = !this.dateHoursKey;
      this.search();
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getRooms() {
      const dates = Object.keys(this.conferenceScheduleTimes);
      return this.conferenceScheduleTimes[dates[this.tab]];
    },
    getAvailableDates() {
      this.$axiosFiseApi
        .get(`/conference-schedules/available-dates`, {
          params: { fairId: this.fair.id },
        })
        .then((res) => {
          this.availableDates = res.data;
        })
        .catch(() => {
          // TODO los catch se deben reportar
        });
    },
    getConferenceRooms() {
      this.$axiosFiseApi
        .get(`/conference-rooms/all`, { params: { fairId: this.fair.id } })
        .then((res) => {
          this.conferenceRooms = res.data;
        })
        .catch(() => {
          // TODO los catch se deben reportar
        });
    },
    getConferenceScheduleTimes() {
      this.$axiosFiseApi
        .get(`/conference-schedules/successes`, {
          params: { fairId: this.fair.id },
        })
        .then((res) => {
          this.conferenceScheduleTimes = res.data;
        })
        .catch(() => {
          // TODO los catch se deben reportar
        });
    },
    getCompanies() {
      this.$axiosFiseApi
        .get(`/conference-bookings/successes/companies`, {
          params: { fairId: this.fair.id },
        })
        .then((res) => {
          this.companies = res.data;
        })
        .catch(() => {
          // TODO los catch se deben reportar
        });
    },
    getKeywords() {
      this.$axiosFiseApi
        .get(`/conference-keywords/all`, { params: { fairId: this.fair.id } })
        .then((res) => {
          this.keywords = res.data;
        })
        .catch(() => {
          // TODO los catch se deben reportar
        });
    },
    getDayWeek(date) {
      return moment(date).format("ddd").substring(0, 3);
    },
    getDayMonth(value) {
      return moment(value).format("D");
    },
    getMonth(value) {
      return moment(value).format("MMMM").substring(0, 3);
    },
    search() {
      this.$refs.conferenceBookings.getDataFromApi();
    },
    show(item) {
      this.$refs.conferenceDetailModal
        .open(item)
        .then(() => {})
        .catch((error) => {
          throw error;
        });
    },
  },
  watch: {
    date(val) {
      if (val) {
        if (val.from && val.to) {
          this.params.date = [];
          this.params.date[0] = val.from;
          this.params.date[1] = val.to;
        }
      }
    },
    endHour(val) {
      this.params.endHour = val;
    },
    startHour(val) {
      this.params.startHour = val;
    },
  },
};
</script>
<style scoped lang="scss">
.card-border {
  border-left: 10px !important;
  border-color: $secondary-color !important;
  border-style: solid !important;
}

.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  /* display: initial; */
  visibility: hidden;
}
.v-tabs >>> .v-slide-group__prev,
.v-tabs >>> .v-slide-group__next {
  flex: none !important;
  min-width: 30px;
}

.v-tab {
  background-color: #f5f0f0;
  border-radius: 5px;
  color: black;
  min-width: 55px;
  max-width: 100%;
}

.v-tabs >>> .v-slide-group__content {
  padding-top: 5px;
  padding-bottom: 5px;
}

.v-tab:nth-child(2n + 1) {
  margin-left: 15px;
  margin-right: 15px;
}

.v-tabs >>> .v-tabs-slider {
  height: 0% !important;
  width: 0% !important;
}

#item-tab > span:nth-child(2) {
  color: black;
}

.v-tab--active:hover,
.v-tab--active {
  color: white !important;
  background-color: $secondary-color;
}
.v-tabs-items >>> .v-window-item--active {
  height: 100% !important;
}
.custom-v-tab-item {
  width: 100% !important;
}
.custom-icon {
  display: flex;
  align-items: center;
}
.v-data-table tr:hover:not(.v-table__expanded__content) {
  background: none !important;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: auto;
}

.v-expansion-panel > .v-expansion-panel-header.border-tb {
  border: solid 1px;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
}

.v-expansion-panel > .v-expansion-panel-header.elevation-6 {
  border-radius: 7px;
}

.v-expansion-panel-content__wrap {
  min-width: 100% !important;
  width: 100% !important;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding-right: 0px !important;
  padding-bottom: 5px !important;
}

table {
  border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
}

table th {
  background-color: $primary-color;
  color: white !important;
  font-size: 17px !important;
  height: 40px !important;
}

table td + td {
  border-left: 1px solid #dddddd;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.custom-thead {
  background-color: $secondary-color !important;
  color: $primary-color !important;
}

.v-application .my-1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.v-application .py-1 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
</style>
